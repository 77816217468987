import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import Vue from "vue";
import axios from "@axios";

export default function useServicesList() {



  const refServiceListTable = ref(null);
  const reftransferListTable=ref(null)
  const mainServiceList=ref('')
const mainServiceOption=ref([])
  // Table Handlers
   const tableColumnsBen = [
    { key: "id", label: " الرقم",formatter: title,},
    { key: "first_name", label: "الاسم",formatter: title,},
    { key: "last_name", label: "النسبة",formatter: title,},



  ];
  const tableColumns = [

    { key: "id", label: " الرقم",formatter: title,},
    { key: "first_name", label: "الاسم",formatter: title,},
    { key: "last_name", label: "النسبة",formatter: title,},
    { key: "service_status", label: "الحالة",formatter: title,},
   
    { key: "proposing_start_date", label: "تاريخ بدء الحالة",formatter: title,},
    { key: "proposing_end_date", label: "تاريخ إغلاق الحالة",formatter: title,},

    { key: "weaknesses", label: "نقاط الضعف",formatter: title,},
    {
      key: "created_by",
      label: "الموظف",
      formatter: title,

    },
    { key: "other_contact_details", label: "ارقام الهواتف " },

    { key: "actions", label: "الخيارات" },
  ];
  const perPage = ref(5);
  const totalUsers = ref(0);
  const page = ref(5);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const gender = ref(null);
  const familyStatus = ref(null);
  const city = ref(null);
  const town = ref(null);
  const street = ref(null)
  const neighborhood = ref(null)
  const multi_weakness=ref([]);
  const startDate = ref(null);
  const endDate = ref(null);
  const planFilter = ref(null);
  const statusFilter = ref(null);
  const serviceFilter = ref(null);
  const mainService= ref(null);
  const min_age = ref(null);
  const search = ref(null);
const pageNumber=ref(1)
  const max_age = ref(null);
  const datatest = ref([]);
  const datastatistics = ref([]);
  const ExcelDone=ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refServiceListTable.value
      ? refServiceListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (page.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (page.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refServiceListTable.value.refresh();
  };

  watch(
    [
      page,
      pageNumber,
      perPage,
      searchQuery,
      startDate,
      city,
      town,
      neighborhood,
      street,
      multi_weakness,
      endDate,
      gender,
      familyStatus,
      serviceFilter,
      mainService,
      min_age,
      search,
      max_age,
      planFilter,
      statusFilter,

    ],
    () => {
      refetchData();
    }
  );
const requestStatus=(obj)=>{
// console.log(obj)
  store
  .dispatch("app-services/requestStatus", obj)
  .then((response) => {
    Vue.swal({
      title: `تم طلب بدء الحالة`,

      icon: "success",
      confirmButtonText: "موافق",
      customClass: {
        confirmButton: "btn btn-primary",
      },
      buttonsStyling: false,
    });
     refetchData();


  })
  .catch(() => {
     Vue.swal({
      title: `${response.data.message}`,
  icon: "error",

    confirmButtonText: "موافق",
    customClass: {
      confirmButton: "btn btn-primary",
    },
    buttonsStyling: false,
  });
  });

}
  const changeStatus=(id,statusid)=>{
    //console.log(id)
    Vue.swal({
      title: "  هل متأكد من تغيير الحالة  ؟",
      icon: 'warning',

confirmButtonText: "موافق",
customClass: {
  confirmButton: "btn btn-primary",
},
buttonsStyling: false,
}).then(result => {
  if (result.value) {

    store
      .dispatch("app-services/changeStatus", {
        id: id,
        statusid:statusid
      })
      .then((response) => {
        Vue.swal({
          title: `${response.data.message}`,

          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
         refetchData();


      })
      .catch(() => {
         Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
    }})
  }

  const openStatus = (data) => {
console.log(data)
    store
      .dispatch("app-services/OpenStatus", {
        id: data.id,
        proposing_start_date:data.start_date,
      })
      .then((response) => {
         refetchData();
         Vue.swal({
          title: `تم  بدء الحالة`,
    
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });

      })
      .catch((res) => {
       // console.log(res)
         Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
              // title: res.message
              
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
  };
  const openStatusImmediate = (id) => {

    store
      .dispatch("app-services/openStatusImmediate", {
        id: id,
      })
      .then((response) => {
         refetchData();
         Vue.swal({
          title: `تم  بدء الحالة`,
    
          icon: "success",
          confirmButtonText: "موافق",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });


      })
      .catch(() => {
         Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
  };
  const fileExcel= async(excelColumns)=>{

      Vue.swal({
        title: "الرجاء الانتظار الى حين استكمال كل البيانات",
        icon: "success",

  confirmButtonText: "موافق",
    allowEscapeKey: false,

  allowOutsideClick: false,
  customClass: {
    confirmButton: "btn btn-primary",
  },
  buttonsStyling: false,
});
await  store
    .dispatch("app-services/fetchexcel", {
      gender: gender.value,
      family_status:familyStatus.value,
      city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
      minDate: startDate.value,
      maxDate: endDate.value,
      service_status: statusFilter.value,
      service: serviceFilter.value,
      main_service:mainService.value,
      search:search.value,
      min_age: min_age.value,
      max_age: max_age.value,
      
      excelColumns
    })
    .then((response) => {

      // const { data ,total} = response.data.data;
      // const services = response.data.data.map(el =>
      //   {
      //    if(el.service?.length !== 0) {
      //    console.log(el.service.join(','))
      //    return el.service.map((t) => t).join(',')
      //    }
         
      // datatest.value =response.data.data;
      //   }
      //  )
      // datatest.value= response.data.data
       let x = response.data.data;
       datatest.value = x.map(el => {
         el.service? el.service = el.service.join(',') : null;
         
         return el;
       }).map(el => {
        el.weakness? el.weakness = el.weakness.join(',') : null;
        
        return el;
      });
   
// console.log(datatest.value)
      Vue.swal({
        title: "تم جلب جميع البيانات",
        icon: "success",
       allowEscapeKey: false,

  allowOutsideClick: false,
  confirmButtonText: "موافق",
  customClass: {
    confirmButton: "btn btn-primary",
  },
  buttonsStyling: false,
});

    })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في استرجاع البيانات",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

  }
  const fetchstatistics= async()=>{
await  store
  .dispatch("app-services/fetchstatistics", {
    gender: gender.value,
    familyStatus:familyStatus.value,
    city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
    minDate: startDate.value,
    maxDate: endDate.value,
    service_status: statusFilter.value,
    service: serviceFilter.value,
    main_service:mainService.value,
    search:search.value,
    min_age: min_age.value,
    max_age: max_age.value,


    page: page.value,
  })
  .then((response) => {

    // const { data ,total} = response.data.data;
    datastatistics.value =response.data.data;
//console.log(response.data.data)


  })
    .catch(() => {
      toast({
        component: ToastificationContent,
        props: {
          title: "حدثت مشكلة في استرجاع البيانات",
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    });

}
  const fetchBeneficiary = (ctx, callback) => {


    store
      .dispatch("app-services/fetchBeneficiary", {
        gender: gender.value,
        familyStatus:familyStatus.value,
        city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
        minDate: startDate.value,
        maxDate: endDate.value,
        service_status: statusFilter.value,
        service: serviceFilter.value,
        main_service:mainService.value,
        search:search.value,
        min_age: min_age.value,
        max_age: max_age.value,
        page_size: perPage.value,

        page: page.value,
      })
      .then((response) => {
       //console.log(response.data)
        const { data ,total} = response.data;
        callback(data);
          totalUsers.value = total;





      })
      .catch(() => {
         Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
  };
  const fetchEXcel = (ctx, callback) => {
   
   // console.log("pageNumber.value",pageNumber.value)
let x=pageNumber.value
    store
      .dispatch("app-services/fetchBenServices", {
        gender: gender.value,
        familyStatus:familyStatus.value,
        city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
        minDate: startDate.value,
        maxDate: endDate.value,
        service_status: statusFilter.value,
        service: serviceFilter.value,
        main_service:mainService.value,
        search:search.value,
        min_age: min_age.value,
        max_age: max_age.value,
        page_size: perPage.value,

        page: pageNumber.value,
      })
      .then((response) => {
        
        pageNumber.value=x
   //  console.log(page)
       //console.log(response.data)
        const { data ,total} = response.data;
        callback(data);
          totalUsers.value = total;





      })
      .catch(() => {
         Vue.swal({
              title: "حدثت مشكلة في استرجاع البيانات",
      icon: "error",

        confirmButtonText: "موافق",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
      });
  };
  const succesStory = (id) => {

    store
      .dispatch("app-services/SucessStory", {
        id: id,
      })
      .then((response) => {

        // toast(
        //   {
        //     component: ToastificationContent,
        //     props: {
        //       title: "تم تميزها كقصة نجاح ",
        //       icon: "StarIcon",
        //       variant: "success",
        //     },
        //   },
        //   {
        //     position: "top-center",
        //   }
        // );
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في استرجاع البيانات",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };
  const fetchBenServices = (ctx, callback) => {

    store
      .dispatch("app-services/fetchBenServices", {
        gender: gender.value,
        familyStatus:familyStatus.value,
        city: city.value,
        town: town.value,
        neighborhood: neighborhood.value,
        street:street.value,
        multi_weakness:multi_weakness.value,
        minDate: startDate.value,
        maxDate: endDate.value,
        service_status: statusFilter.value,
        service: serviceFilter.value,
         main_service:mainService.value,
         search:search.value,
        min_age: min_age.value,
        max_age: max_age.value,
        page_size: perPage.value,

        page: page.value,
      })
      .then((response) => {
      
        // localStorage.setItem('page', page.value)
        const { data ,total} = response.data.data;
        // datatest.value =response.data.data;
        let x = response.data.data;
        datatest.value = x.map(el => {
          el.service = el.service.join(',');
          el.weakness = el.weakness.join(',');
          return el;
        });
    //  console.log(page)
        callback(data);
        totalUsers.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "حدثت مشكلة في استرجاع البيانات",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });

  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = (role) => {
    if (role === "subscriber") return "primary";
    if (role === "author") return "warning";
    if (role === "Information Manager") return "success";
    if (role === "Data Entry") return "info";
    if (role === "admin") return "danger";
    return "primary";
  };

  const resolveUserRoleIcon = (role) => {
    if (role === 0) return "PauseIcon";
    if (role === "author") return "SettingsIcon";
    if (role === "maintainer") return "DatabaseIcon";
    if (role === "Data Entry") return "Edit2Icon";
    if (role === "Information Manager") return "PauseIcon";
    return "UserIcon";
  };

  const resolveUserStatusVariant = (status) => {
    if (status === "قيد الانتظار") return "danger";
    if (status === "مغلقة") return "success";
    if (status === "مفتوحة") return "warning";
    return "primary";
  };
  const donutChart= {
    series: [40, 60],
    chartOptions: {
      legend: {
        show: true,
        position: 'bottom',
        fontSize: '14px',
        fontFamily: 'Montserrat',
      },
      colors: [

      ],
      dataLabels: {
        enabled: true,
        formatter(val) {
          // eslint-disable-next-line radix
          return `${parseInt(val)}%`
        },
      },
       plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}%`
                },
              },

            },
          },
        },
      },
      labels: ['الإناث', 'الذكور'],
      responsive: [
        {
          breakpoint: 992,
          options: {
            chart: {
              height: 380,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
        {
          breakpoint: 576,
          options: {
            chart: {
              height: 320,
            },
            plotOptions: {
              pie: {
                donut: {
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1.5rem',
                    },
                    value: {
                      fontSize: '1rem',
                    },
                    total: {
                      show: true,
              fontSize: '1rem',
              label: 'Comments',
              formatter() {
                return '80%'
              },
                    },
                  },
                },
              },
            },
            legend: {
              show: true,
            },
          },
        },
      ],
    },
  }

  return {
    pageNumber,
    donutChart,
    fileExcel,
    fetchBenServices,
    fetchEXcel,
    fetchBeneficiary,
    datatest,
    datastatistics,
    tableColumns,
    tableColumnsBen,
    perPage,
    page,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    mainServiceList,
    mainServiceOption,
    sortBy,
    isSortDirDesc,
    refServiceListTable,
    reftransferListTable,
    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,
    ExcelDone,
    mainServiceOption,
    changeStatus,
    requestStatus,

    openStatus,
    openStatusImmediate,
    succesStory,
    mainServiceList,
    // Extra Filters
    gender,
    familyStatus,
    city,
    town,
    street,
    neighborhood,
    multi_weakness,
    startDate,
    endDate,
    planFilter,
    statusFilter,
   mainService,
   serviceFilter,
   search,
    min_age,

    max_age,
  };
}
